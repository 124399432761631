<template>
  <div class="hidden" />
</template>

<script setup>
// import * as Sentry from "@sentry/vue";

const { $eventBus } = useNuxtApp();
const config = useRuntimeConfig();
const ws = ref(null);
const reconnectInterval = ref(null);
const reconnectDelay = 5000;
let retryCount = 0;

const connectWebSocket = () => {
  // console.log('Attempting to connect to WebSocket server...');

  ws.value = new WebSocket(config.public.wsBaseUrl);

  ws.value.onmessage = (event) => {
    const { status, message, body } = JSON.parse(event.data);
    if (status === 'ping') {
      // console.log('Received "ping", sending "pong"...');
      ws.value.send(JSON.stringify({ status: 'pong' }));
    } else {
      // console.log(`Received message "${message}", emitting...`);
      $eventBus.emit(message, body);
    }
  };

  ws.value.onopen = () => {
    // console.log('Connected to WebSocket server');
    retryCount = 0;
    if (reconnectInterval.value) {
      clearInterval(reconnectInterval.value);
      reconnectInterval.value = null;
    }
  };

  ws.value.onclose = () => {
    // console.log('Disconnected from WebSocket server');
    retryWebSocketConnection();
  };

  ws.value.onerror = () => {
    if (ws.value) {
      ws.value.close();
    }
  };
};

const subscribe = async () => {
  try {
    const result = await $fetch(config.public.wsSignUrl, {
      method: 'POST',
      body: {
        sid: useCookie('auth_sid').value
      }
    });
    if (result.status === 'success') {
      connectWebSocket();
      // console.log(result.message);
    }
  } catch (error) {
    // Sentry.captureException(error);
    // console.log(error.message);
  }
}

const retryWebSocketConnection = () => {
  retryCount++;
  // console.log(`Reconnection attempt #${retryCount}`);
  reconnectInterval.value = setTimeout(subscribe, reconnectDelay * retryCount);
};

onMounted(async () => {
  if (import.meta.client) {
    await subscribe();
  }
});

onBeforeUnmount(() => {
  if (import.meta.client && ws.value) {
    ws.value.close();
    if (reconnectInterval.value) {
      clearInterval(reconnectInterval.value);
    }
  }
});
</script>
