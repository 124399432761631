import _ from 'lodash-es';
import { siteSettings } from "@settings/site.settings";

export default function (data, type, fallback = '', translated = null) {
  const routeLang = useRouteLang();
  const languages = _.has(data, 'languages') ? data.languages : (data || []);
  const isTranslated = translated === null || translated ? {} : { isTranslated: false };

  let value = routeLang.locale.value ? _.find(languages, _.assign({ type, language: { locale: routeLang.locale.value } }, isTranslated)) : null;
  if (value && value?.content) return value?.content;

  value = _.find(languages, _.assign({ type, language: { locale: siteSettings.language.locale } }, isTranslated));
  if (value && value?.content) return value?.content;

  value = type ? _.find(languages, _.assign({ type }, isTranslated)) : null;
  if (value && value?.content) return value?.content;

  const contents = _.has(data, 'contents') ? data.contents : (data || []);

  value = routeLang.locale.value ? _.find(contents, _.assign({ type, language: { locale: routeLang.locale.value } }, isTranslated)) : null;
  if (value && value?.content) return value?.content;

  value = _.find(contents, _.assign({ type, language: { locale: siteSettings.language.locale } }, isTranslated));
  if (value && value?.content) return value?.content;

  value = type ? _.find(contents, _.assign({ type }, isTranslated)) : null;
  if (value && value?.content) return value?.content;

  return _.get(data, type, fallback);
}
