import _ from 'lodash-es';

export default function (data, type) {
  const routeLang = useRouteLang();
  const languages = _.has(data, 'languages') ? data.languages : (data || []);

  let value = routeLang.locale.value ? _.find(languages, { type, language: { locale: routeLang.locale.value } }) : null;
  if (value && value?.content && value.language?.locale === routeLang.locale.value) return true;

  const contents = _.has(data, 'contents') ? data.contents : (data || []);

  value = _.find(contents, { type });
  if (value && value?.content &&  value.language?.locale === routeLang.locale.value) return true;

  return false;
}
