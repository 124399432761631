<template>
    <div ref="observedElement" class="flex flex-col p-2 flex-1">
      <div :class="[ 'px-1 text-sm text-left prose max-w-none', item.id === selected || !isRead ? '' : 'text-white' ]">
        <span
          v-for="(chunk, idx) in markdown(item.content)"
          :key="idx"
        >
          <Link
            v-if="chunk.type === 'link'"
            :href="chunk.href"
            :class="[ 'underline hover:no-underline', item.id === selected || !isRead ? '' : 'text-white' ]"
            @click="closeModal"
          >
            <span v-html="formatChunk(chunk.content)" />
          </Link>
          <span v-else>
            <span v-html="formatChunk(chunk.content)" />
          </span>
        </span>
        {{ (!item.content && item?.images?.length ? '' : ' ') }}
      </div>
      <MessageImages v-if="item?.images?.length" :item="item" />
      <div v-if="item.insertedAt" class="flex items-center justify-start text-2xs italic whitespace-nowrap px-1 mt-1 text-right">
        <div :class="item.id === selected || !isRead ? 'text-gray-500' : 'text-gray-300'">{{ $luxon.fromISO(item.insertedAt).setLocale(routeLang.code.value).toRelative() }}</div>
        <div v-if="position === 'right'" v-tippy="$t('text-is-read')" class="flex items-center justify-center">
          <CheckIcon v-if="item.isRead" :class="[ 'w-3 h-3 ml-2', item.id === selected ? 'text-accent-dark' : 'text-accent-light' ]" :stroke-width="4" />
        </div>
      </div>
    </div>
</template>

<script setup>
import _ from 'lodash-es';
import { useAuthStore } from '@stores/auth';
import MessageImages from "@components/chat/message-images";
import Link from "@components/ui/link/link";
import CheckIcon from "@components/icons/check-icon";

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  selected: {
    type: Number,
    default: null
  },
  isRead: {
    type: Boolean,
    default: true
  },
  position: {
    type: String,
    required: true
  }
});

const { $eventBus, $luxon } = useNuxtApp();
const auth = useAuthStore();
const routeLang = useRouteLang();
const observedElement = ref(null);
const observer = ref(null);
const timeoutId = ref(null);

const emit = defineEmits(['read']);

const scheduleRead = () => {
  emit('read');
}

const closeModal = () => {
  $eventBus.emit('modal:close');
}

const emojiRanges = [[8596,8601],[8617,8618],[8986,8987],[9000,9000],[9167,9167],[9193,9203],[9208,9210],[9410,9410],[9642,9643],[9654,9654],[9664,9664],[9723,9726],[9728,9732],[9742,9742],[9745,9745],[9748,9749],[9752,9752],[9757,9757],[9760,9760],[9762,9763],[9766,9766],[9770,9770],[9774,9775],[9784,9786],[9792,9792],[9794,9794],[9800,9811],[9824,9824],[9827,9827],[9829,9830],[9832,9832],[9851,9851],[9855,9855],[9874,9879],[9881,9881],[9883,9884],[9888,9889],[9898,9899],[9904,9905],[9917,9918],[9924,9925],[9928,9928],[9934,9935],[9937,9937],[9939,9940],[9961,9962],[9968,9973],[9975,9978],[9981,9981],[9986,9986],[9989,9989],[9992,9997],[9999,9999],[10002,10002],[10004,10004],[10006,10006],[10013,10013],[10017,10017],[10024,10024],[10035,10036],[10052,10052],[10055,10055],[10060,10060],[10062,10062],[10067,10069],[10071,10071],[10083,10084],[10133,10135],[10145,10145],[10160,10160],[10175,10175],[10548,10549],[11013,11015],[11035,11036],[11088,11088],[11093,11093],[12336,12336],[12349,12349],[12951,12951],[12953,12953],[65039,65039],[126980,126980],[127183,127183],[127344,127345],[127358,127359],[127374,127374],[127377,127386],[127462,127487],[127489,127490],[127514,127514],[127535,127535],[127538,127546],[127568,127569],[127744,127777],[127780,127891],[127894,127895],[127897,127899],[127902,127984],[127987,127989],[127991,127994],[128000,128253],[128255,128317],[128329,128334],[128336,128359],[128367,128368],[128371,128378],[128391,128391],[128394,128397],[128400,128400],[128405,128406],[128420,128421],[128424,128424],[128433,128434],[128444,128444],[128450,128452],[128465,128467],[128476,128478],[128481,128481],[128483,128483],[128488,128488],[128495,128495],[128499,128499],[128506,128591],[128640,128709],[128715,128722],[128736,128741],[128745,128745],[128747,128748],[128752,128752],[128755,128760],[129296,129338],[129340,129342],[129344,129349],[129351,129356],[129360,129387],[129408,129431],[129472,129472],[129488,129510]];

const isEmoji = (codePoint) => {
  return emojiRanges.some(([start, end]) => codePoint >= start && codePoint <= end);
}

const formatChunk = (content) => {
  return _.chain([...content])
    .map(char => {
      const codePoint = char.codePointAt(0);

      if (isEmoji(codePoint)) {
        return `<span class="text-lg">${_.escape(char)}</span>`;
      } else if (codePoint === 10) {
        return "<br>";
      }
      return _.escape(char);
    })
    .join('')
    .value();
}

onMounted(() => {
  if (auth.isLoggedIn && auth.idSupplier) {
    observer.value = new window.IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (!props.isRead) {
          if (entry.isIntersecting) {
            timeoutId.value = setTimeout(scheduleRead, 5000);
          } else if (timeoutId.value) {
            clearTimeout(timeoutId.value);
            timeoutId.value = null;
          }
        }
      });
    }, {});

    if (observedElement.value) {
      observer.value.observe(observedElement.value);
    }
  }
});

onUnmounted(() => {
  if (auth.isLoggedIn && auth.idSupplier && observedElement.value) {
    observer.value.unobserve(observedElement.value);
  }
});

</script>
