import revive_payload_client_4sVQNw7RlN from "/home/aquarist/www/prod/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/aquarist/www/prod/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/aquarist/www/prod/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/aquarist/www/prod/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/aquarist/www/prod/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/aquarist/www/prod/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/aquarist/www/prod/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/aquarist/www/prod/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/aquarist/www/prod/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/aquarist/www/prod/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/home/aquarist/www/prod/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_TvWhUGmZxm from "/home/aquarist/www/prod/node_modules/nuxt-open-fetch/dist/runtime/plugin.mjs";
import plugin_Jozdw60ZsE from "/home/aquarist/www/prod/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/home/aquarist/www/prod/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/home/aquarist/www/prod/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import error_handler_VFH3VvK7yG from "/home/aquarist/www/prod/plugins/error-handler.js";
import eventBus_cMx6qAgliS from "/home/aquarist/www/prod/plugins/eventBus.js";
import firebase_client_nS52P0yitD from "/home/aquarist/www/prod/plugins/firebase.client.js";
import google_maps_client_C3Ml2mzhjC from "/home/aquarist/www/prod/plugins/google-maps.client.js";
import google_recaptcha_rYK9Kziv68 from "/home/aquarist/www/prod/plugins/google-recaptcha.ts";
import luxon_s40zPPcmn5 from "/home/aquarist/www/prod/plugins/luxon.js";
import navigation_tracker_4JJrJzbuys from "/home/aquarist/www/prod/plugins/navigation-tracker.js";
import route_checker_client_RVNVVW3PyS from "/home/aquarist/www/prod/plugins/route-checker.client.js";
import sentry_client_shVUlIjFLk from "/home/aquarist/www/prod/plugins/sentry.client.ts";
import vue_countdown_Ru3cHbgzey from "/home/aquarist/www/prod/plugins/vue-countdown.ts";
import vue_tippy_YMxDLgIIQU from "/home/aquarist/www/prod/plugins/vue-tippy.ts";
import vue3_toastify_OGYNDsiW9E from "/home/aquarist/www/prod/plugins/vue3-toastify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_TvWhUGmZxm,
  plugin_Jozdw60ZsE,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  error_handler_VFH3VvK7yG,
  eventBus_cMx6qAgliS,
  firebase_client_nS52P0yitD,
  google_maps_client_C3Ml2mzhjC,
  google_recaptcha_rYK9Kziv68,
  luxon_s40zPPcmn5,
  navigation_tracker_4JJrJzbuys,
  route_checker_client_RVNVVW3PyS,
  sentry_client_shVUlIjFLk,
  vue_countdown_Ru3cHbgzey,
  vue_tippy_YMxDLgIIQU,
  vue3_toastify_OGYNDsiW9E
]