import { ROUTES } from "@utils/routes";
import FacebookIcon from "@components/icons/social/facebook-icon";
import XTwitterIcon from "@components/icons/social/x-twitter-icon";
import Fish from "@components/icons/category/fish";
import Plants from "@components/icons/category/plants";
import Equipment from "@components/icons/category/equipment";
import Other from "@components/icons/category/other";
import Pipe from "@components/icons/category/pipe";
import Tobacco from "@components/icons/category/tobacco";
import Cigar from "@components/icons/category/cigar";
import Accessories from "@components/icons/category/accessories";
import FishIcon from "@components/icons/fish-icon";
import WatchdogIcon from "@components/icons/watchdog-icon";

export const siteSettings = {
  name: "Aquarist",
  description: "Vše pro vaše akvárium",
  logo: {
    aquarist: {
      header: {
        width: 126,
        height: 60,
        class: 'w-22 h-10.5 lg:w-31.5 lg:h-15'
      },
      footer: {
        width: 63,
        height: 30,
        class: 'w-16 h-8'
      }
    },
    pipeclub: {
      header: {
        width: 170,
        height: 50,
        class: 'w-34 h-10 lg:w-42.5 lg:h-12.5 -translate-y-3 lg:-translate-y-4'
      },
      footer: {
        width: 126,
        height: 60,
        class: 'w-31.5 h-15 -translate-y-4'
      }
    },
  },
  sitemap: {
    aquarist: 'foraquarist',
    pipeclub: 'pipeclub',
  },
  language: {
    id: 1,
    code: "cs",
    locale: "cs_CZ",
    name: "Čeština",
  },
  currency: {
    code: "CZK",
    name: "Koruna česká",
    sign: "Kč",
    signPosition: "after",
    decimals: 2,
    decimalsSeparator: ",",
    thousandsSeparator: "",
    currencyRate: 1
  },
  product: {
    placeholderImage: {
      aquarist: "/placeholder/aquarist-placeholder.svg",
      pipeclub: "/placeholder/pipeclub-placeholder.svg",
    },
    card: {
      tags: {
        parameters: [ 101, 201, 301, 1 ]
      }
    },
    edit: {
      product: {
        show: {
          category: false,
          status: false
        }
      },
      variant: {
        show: {
          add: false,
          header: false,
          category: true,
          vatRate: false
        },
        required: {
          price: false
        },
        placeholder: {
          name: 'text-ad-name-placeholder',
          content: 'text-ad-content-placeholder'
        }
      },
      fields: {
        show: {
          optional: false
        }
      }
    }
  },
  author: {
    aquarist: {
      label: "text-website-with-domain",
      image: "/banner/aquarist.jpg",
      logo: {
        white: "/logo/aquarist-white.svg",
        black: "/logo/aquarist-black.svg",
        color: "/logo/aquarist-color.svg",
      },
      websiteUrl: "https://www.foraquarist.com",
      address: "Rumunská 1798/1, 120 00 Praha - Nové Město, Česká republika",
      email: "info@foraquarist.com",
      phone: "+420 603 449 602",
      social: [
        {
          link: "https://www.facebook.com/profile.php?id=61556821824752",
          icon: "FacebookIcon",
          hoverClass: "hover:text-social-facebook",
          label: "text-facebook"
        },
        {
          link: "https://www.instagram.com/foraquarist/",
          icon: "InstagramIcon",
          hoverClass: "hover:text-social-instagram",
          label: "text-instagram"
        }
      ],
    },
    pipeclub: {
      label: 'text-website-with-domain',
      image: "/banner/pipeclub.jpg",
      logo: {
        white: "/logo/pipeclub-white.svg",
        black: "/logo/pipeclub-black.svg",
        color: "/logo/pipeclub-color.svg",
      },
      websiteUrl: "https://www.pipeclub.eu",
      address: "Rumunská 1798/1, 120 00 Praha - Nové Město, Česká republika",
      email: "info@pipeclub.eu",
      phone: "+420 603 449 602",
    },
  },
  watchdog: {
    aquarist: {
      icon: FishIcon,
    },
    pipeclub: {
      icon: WatchdogIcon,
    }
  },
  shareLinks: [
    {
      href: "https://www.facebook.com/sharer/sharer.php?u={URL}",
      icon: FacebookIcon,
      label: "text-facebook"
    },
    {
      href: "https://twitter.com/intent/tweet?url={URL}&text={TEXT}",
      icon: XTwitterIcon,
      label: "text-x-twitter"
    }
  ],
  homeLinks: [
    { routeCode: ROUTES.CODE.HOME, icon: null, label: "nav-home", minWidth: 1280, className: 'hidden lg:block' },
    { routeCode: ROUTES.CODE.INSPIRATION, icon: null, label: "nav-menu-inspiration", minWidth: 1280, className: '' },
    { routeCode: ROUTES.CODE.NEWS, icon: null, label: 'nav-menu-news', minWidth: 1024, className: 'hidden lg:block' },
    { routeCode: ROUTES.CODE.HELP, icon: null, label: 'nav-menu-faq', minWidth: 1366, className: 'hidden xl:block' },
  ],
  headerLinks: [
    { routeCode: ROUTES.CODE.HOME, icon: null, label: "nav-home", minWidth: 1024, className: 'hidden lg:block' },
    { routeCode: ROUTES.CODE.OFFERS, icon: null, label: "nav-menu-offers", minWidth: 1536, className: 'hidden xl:block' },
    { routeCode: ROUTES.CODE.REQUESTS, icon: null, label: 'nav-menu-requests', minWidth: 1920, className: 'hidden 2xl:block' },
    { routeCode: ROUTES.CODE.AUCTIONS, icon: null, label: 'nav-menu-auctions', minWidth: 1536, className: 'hidden xl:block' },
    { routeCode: ROUTES.CODE.NEWS, icon: null, label: 'nav-menu-news', minWidth: 1280, className: 'hidden lg:block' },
    { routeCode: ROUTES.CODE.HELP, icon: null, label: 'nav-menu-faq', minWidth: 1280, className: 'hidden xl:block' },
  ],
  mobileMenuLinks: [
    { routeCode: ROUTES.CODE.HOME, icon: null, label: "nav-home" },
    { routeCode: ROUTES.CODE.OFFERS, icon: null, label: "nav-menu-offers" },
    { routeCode: ROUTES.CODE.REQUESTS, icon: null, label: "nav-menu-requests" },
    { routeCode: ROUTES.CODE.AUCTIONS, icon: null, label: "nav-menu-auctions" },
    { routeCode: ROUTES.CODE.NEWS, icon: null, label: 'nav-menu-news' },
    { routeCode: ROUTES.CODE.HELP, icon: null, label: "nav-menu-faq" },
    { routeCode: ROUTES.CODE.INSPIRATION, icon: null, label: "nav-menu-inspiration" },
    { routeCode: ROUTES.CODE.FAVORITES, icon: null, label: "nav-menu-my-favorites" },
  ],
  authorizedLinks: [
    // { routeCode: ROUTES.CODE.WAITING, label: "auth-menu-waiting-orders", role: 'supplier' },
    { routeCode: ROUTES.CODE.ADS_MY, label: "auth-menu-my-ads", role: 'supplier' },
    { routeCode: ROUTES.CODE.SALES, label: "auth-menu-my-sales", role: 'supplier', badge: 'unreadSalesMessagesCount' },
    { routeCode: ROUTES.CODE.ORDERS, label: "auth-menu-my-orders", role: 'supplier', badge: 'unreadPurchasesMessagesCount' },
    { routeCode: ROUTES.CODE.PROFILE, label: "auth-menu-profile", role: 'customer' },
    { routeCode: ROUTES.CODE.SETTINGS_NOTIFICATION, label: "auth-menu-settings-notifications" },
    { routeCode: ROUTES.CODE.LOGOUT, label: "auth-menu-logout", role: 'customer' },
  ],
  dashboardSidebarMenu: [
    // { routeCode: ROUTES.CODE.WAITING, label: "profile-sidebar-waiting-orders", role: 'supplier', routeNamePrefix: 'unfinished___' },
    { routeCode: ROUTES.CODE.SALES, label: "profile-sidebar-my-sales", role: 'supplier', routeNamePrefix: 'sales___' },
    { routeCode: ROUTES.CODE.ORDERS, label: "profile-sidebar-my-orders", role: 'supplier', routeNamePrefix: 'orders___' },
    { routeCode: ROUTES.CODE.PROFILE, label: "profile-sidebar-profile", role: 'customer', routeNamePrefix: 'profile___' },
    { routeCode: ROUTES.CODE.SETTINGS_NOTIFICATION, label: "profile-sidebar-settings-notifications", role: 'customer', routeNamePrefix: 'settings-notifications___' },
    { routeCode: ROUTES.CODE.HELP, label: "profile-sidebar-help", routeNamePrefix: 'help___' },
    { routeCode: ROUTES.CODE.CONTACT, label: "profile-sidebar-contact", routeNamePrefix: 'contact___' },
  ],
  categoryHomePanel: {
    aquarist: [
      { id: 101, icon: Fish, label: 'text-fish' },
      { id: 103, icon: Plants },
      { id: 104, icon: Equipment },
      { id: 106, icon: Other },
    ],
    pipeclub: [
      { id: 306, icon: Pipe },
      { id: 307, icon: Tobacco },
      { id: 308, icon: Cigar },
      { id: 309, icon: Accessories },
    ]
  },
  footerLinks: {
    aquarist: [
      {
        label: 'text-ads',
        links: [
          { routeCode: ROUTES.CODE.AD_ADD, label: 'text-add-ad' },
          { routeCode: ROUTES.CODE.ADS, label: 'text-browse-ads' },
          { routeCode: ROUTES.CODE.FAVORITES, label: 'text-favorite-ads' },
          { routeCode: ROUTES.CODE.RULES, label: 'text-rules-of-use' },
        ]
      },
      {
        label: 'text-could-interest-you',
        links: [
          { routeCode: ROUTES.CODE.HELP, label: 'text-faq' },
          { routeCode: ROUTES.CODE.WHY_WITH_US, label: 'text-why-submit-ad' },
          { routeCode: ROUTES.CODE.TERMS, label: 'text-terms-and-conditions', sidebar: 'TERMS' },
          { routeCode: ROUTES.CODE.PRIVACY, label: 'text-gdpr', sidebar: 'PRIVACY' },
        ]
      },
      {
        label: 'text-website-name',
        links: [
          { routeCode: ROUTES.CODE.HOME, label: "text-homepage" },
          { routeCode: ROUTES.CODE.INSPIRATION, label: "nav-menu-inspiration" },
          { routeCode: ROUTES.CODE.NEWS, label: 'text-read-news' },
          { routeCode: ROUTES.CODE.APP, label: 'text-mobile-app' },
        ]
      },
      {
        label: 'text-contact',
        links: [
          { routeCode: ROUTES.CODE.CONTACT, label: "text-contact-us" },
          { href: 'mailto:info@foraquarist.com', title: 'info@foraquarist.com', external: true },
          { href: 'tel:+420603449602', title: '+420 603 449 602', external: true },
          { type: 'socialIcons' },
        ]
      }
    ],
    pipeclub: [
      {
        label: 'text-ads',
        links: [
          { routeCode: ROUTES.CODE.AD_ADD, label: 'text-add-ad' },
          { routeCode: ROUTES.CODE.ADS, label: 'text-browse-ads' },
          { routeCode: ROUTES.CODE.FAVORITES, label: 'text-favorite-ads' },
          { routeCode: ROUTES.CODE.RULES, label: 'text-rules-of-use' },
        ]
      },
      {
        label: 'text-could-interest-you',
        links: [
          { routeCode: ROUTES.CODE.HELP, label: 'text-faq' },
          { routeCode: ROUTES.CODE.WHY_WITH_US, label: 'text-why-submit-ad' },
          { routeCode: ROUTES.CODE.TERMS, label: 'text-terms-and-conditions', sidebar: 'TERMS' },
          { routeCode: ROUTES.CODE.PRIVACY, label: 'text-gdpr', sidebar: 'PRIVACY' },
        ]
      },
      {
        label: 'text-website-name',
        links: [
          { routeCode: ROUTES.CODE.HOME, label: "text-homepage" },
          { routeCode: ROUTES.CODE.INSPIRATION, label: "nav-menu-inspiration" },
          { routeCode: ROUTES.CODE.NEWS, label: 'text-read-news' },
          { routeCode: ROUTES.CODE.APP, label: 'text-mobile-app' },
        ]
      },
      {
        label: 'text-contact',
        links: [
          { routeCode: ROUTES.CODE.CONTACT, label: "text-contact-us" },
          { href: 'mailto:info@pipeclub.eu', title: 'info@pipeclub.eu', external: true },
          { href: 'tel:+420603449602', title: '+420 603 449 602', external: true },
        ]
      }
    ]
  }
};
