<template>
    <div v-tippy="{ content: title, placement }" :class="sizeClass">
        <div :class="[ 'relative overflow-hidden rounded-full', sizeClass ]" :aria-label="$t('text-supplier')">
          <Image
            v-if="src"
            :alt="name ? name : title"
            :src="src"
            layout="fill"
            :width="sizeWidth"
            :height="sizeWidth"
            :sizes="`${sizeWidth}px`"
            :loading="loading"
          />
          <div v-else class="flex items-center justify-center text-sm font-bold text-light w-full h-full bg-accent">
            {{ initials }}
          </div>
        </div>
    </div>
</template>

<script setup>
import _chain from 'lodash-es/chain';
import _upperFirst from 'lodash-es/upperFirst';
import Image from "@components/ui/image";

const props = defineProps({
    src: {
        type: String,
        default: ''
    },
    name: {
        type: String,
        default: ''
    },
    title: {
        type: String,
        default: ''
    },
    placement: {
        type: String,
        default: 'top'
    },
    size: {
        type: String,
        default: 'normal'
    },
    loading: {
        type: String,
        default: null
    }
});

const sizeClass = computed(() => {
    return {
        small: 'w-7.5 h-7.5',
        normal: 'w-10 h-10',
        reference: 'w-30 h-30'
    }[props.size];
});

const sizeWidth = computed(() => {
    return {
        small: 30,
        normal: 40,
        reference: 120
    }[props.size];
});

const initials = computed(() => _chain(props.name ? props.name : props.title)
    .split(' ')
    .map((part) => _upperFirst(part.charAt(0)))
    .join('')
    .value()
);

</script>
