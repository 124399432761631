import { defineStore } from 'pinia'
import { SEARCH_KEY } from "@utils/constants";

export const useSearchStore = defineStore(SEARCH_KEY, () => {
  const variantsLoaded = useState('search.variants.loaded', () => false);
  const setVariantsLoaded = (value) => {
    variantsLoaded.value = value;
  }

  const variants = useState('search.variants', () => ({ items: [], pagination: { page: 1, pageCount: 1, itemsPerPage: 18, itemCount: 0 } }));
  const popVariants = () => {
    setVariantsLoaded(false);
    return variants;
  }
  const setVariants = (value) => {
    variants.value = value;
  }

  const inspirationLoaded = useState('search.inspiration.loaded', () => false);
  const setInspirationLoaded = (value) => {
    inspirationLoaded.value = value;
  }

  const inspiration = useState('search.inspiration', () => ({ items: [], pagination: { page: 1, pageCount: 1, itemsPerPage: 18, itemCount: 0 } }));
  const popInspiration = () => {
    setInspirationLoaded(false);
    return inspiration;
  }
  const setInspiration = (value) => {
    inspiration.value = value;
  }

  const newsLoaded = useState('search.news.loaded', () => false);
  const setNewsLoaded = (value) => {
    newsLoaded.value = value;
  }

  const news = useState('search.news', () => ({ items: [], pagination: { page: 1, pageCount: 1, itemsPerPage: 18, itemCount: 0 } }));
  const popNews = () => {
    setNewsLoaded(false);
    return news;
  }
  const setNews = (value) => {
    news.value = value;
  }

  return {
    variantsLoaded, setVariantsLoaded,
    variants, popVariants, setVariants,
    inspirationLoaded, setInspirationLoaded,
    inspiration, popInspiration, setInspiration,
    newsLoaded, setNewsLoaded,
    news, popNews, setNews,
  }
})
