<template>
    <div
      v-tippy="variant.isInCustomerFavorites ? $t('text-remove-from-favorites') : $t('text-add-to-favorites')"
      :class="[ 'w-10 h-10 cursor-pointer flex-shrink-0 flex items-center justify-center rounded-xl', accent ? 'border border-primary-neutral hover:border-primary-dark-hover hover:bg-primary-dark-hover text-light' : 'border border-primary-dark hover:border-primary-dark-hover hover:bg-primary-dark-hover text-primary-dark hover:text-light' ]"
      @click="toggle"
    >
        <BookmarkedIcon v-if="variant.isInCustomerFavorites" class="w-4 h-4" />
        <BookmarkIcon v-else class="w-4 h-4" />
    </div>
</template>

<script setup>
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { PRODUCT_SUBTYPE } from "@utils/constants";
import { useSelectedStore } from '@stores/selected'
import BookmarkIcon from "@components/icons/bookmark-icon";
import BookmarkedIcon from "@components/icons/bookmarked-icon";

const props = defineProps({
  variant: {
    type: Object,
    default: () => {}
  },
  accent: {
    type: Boolean,
    default: false
  }
});

const { $eventBus } = useNuxtApp();
const loading = ref(false);
const store = useSelectedStore();

const toggle = async () => {
  if (loading.value || !checkAuth()) {
      return;
  }
  loading.value = true;
  const { data, error, status } = await useApiFetch(API_ENDPOINTS.ESHOP_PRODUCT_VARIANT_ID_FAVORITE, {
    method: props.variant.isInCustomerFavorites ? 'DELETE' : 'POST',
    path: {
      idVariant: props.variant.id
    }
  });
  if (status.value === 'error') {
    sentryCaptureEvent({
      message: 'variant-favorite.toggle',
      level: 'error',
      extra: { error: error.value }
    });
  }
  $eventBus.emit('variant:update', data.value);
  loading.value = false;

  store.setLoadReset(PRODUCT_SUBTYPE.favorite, true);
}

</script>
