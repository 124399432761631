import { API_ENDPOINTS } from "@utils/api/endpoints";
import { useContentStore } from '@stores/content';

export default async function () {
  const store = useContentStore();
  const routeLang = useRouteLang();

  if (store.loaded) {
    return;
  }

  const { data, error, status } = await useApiFetch(API_ENDPOINTS.MASTER_CONTENT, {
    method: 'POST',
    params: {
      locale: routeLang.locale.value
    }
  });

  if (status.value === 'error') {
    sentryCaptureEvent({
      message: 'load-content-master',
      level: 'error',
      extra: { error: error.value }
    });
  } else {
    store.setVariants(data.value?.variants || { items: [], pagination: { page: 1, pageCount: 1, itemsPerPage: 24, itemCount: 0 } });
    store.setInspiration(data.value?.inspiration || { items: [], pagination: { page: 1, pageCount: 1, itemsPerPage: 24, itemCount: 0 } });
    store.setNews(data.value?.news || { items: [], pagination: { page: 1, pageCount: 1, itemsPerPage: 24, itemCount: 0 } });
    store.setReference(data.value?.reference || { items: [], pagination: { page: 1, pageCount: 1, itemsPerPage: 24, itemCount: 0 } });
  }

}
