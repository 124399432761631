import * as Sentry from "@sentry/vue";
import { API_ENDPOINTS } from "@utils/api/endpoints";

export default async function (id, body) {
  const config = useRuntimeConfig();

  try {
    return await $fetch(`${config.public.appBaseUrl}${API_ENDPOINTS.NOTIFICATION_FCM_ID_CONFIRM.replace('{id}', id)}`, {
      method: 'PUT',
      body
    });
  } catch (error) {
    Sentry.captureException(error);
  }

  return null;
}
