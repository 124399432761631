<template>
  <SearchBox
    v-model="fulltext"
    :label="label"
    name="search"
    :placeholder="$t('common:text-search-placeholder')"
    :on-submit="onSearch"
    :on-clear-search="clearSearch"
    :on-filter="onFilter"
    :on-speech="onSpeech"
  />
</template>

<script setup>
import _ from "lodash-es";
import { ROUTES } from "@utils/routes";
import { CONTENT_TYPE } from "@utils/constants";
import { useUIStore } from '@stores/ui';
import { useSelectedStore } from '@stores/selected'
import { useSearchStore } from '@stores/search';
import SearchBox from "@components/ui/search-box";

const windowSize = useWindowSize();

const route = useRoute();
const routeLang = useRouteLang();
const fulltext = ref(route?.query?.fulltext || '');

defineProps({
  label: {
    type: String,
    required: true,
    default: () => ''
  },
  isDetail: {
    type: Boolean,
    default: false
  }
});

const { $eventBus } = useNuxtApp();
const ui = useUIStore();
const store = useSelectedStore();
const search = useSearchStore();
const routeCategory = useRouteCategory();

watch(() => route.query.fulltext, (value) => fulltext.value = value);

const closeSidebar = () => {
  $eventBus.emit('sidebar:close');
};

const onSearch = _.debounce(async () => {
  if (ui.displaySidebar) {
    closeSidebar();
  }

  await loadProductVariants(
    {
      page: 1,
      limit: search.variants.pagination.itemsPerPage,
      sort: route.query?.sort || 'publishedAt',
      direction: route.query?.direction || 'desc',
      locale: routeLang.locale.value
    },
    { fulltext: fulltext.value }
  );

  await loadContentInspiration(
    {
      type: CONTENT_TYPE.INSPIRATION,
      page: 1,
      limit: search.variants.pagination.itemsPerPage,
      sort: route.query?.sort || 'publishedAt',
      direction: route.query?.direction || 'desc',
      locale: routeLang.locale.value
    },
    { fulltext: fulltext.value }
  );

  await loadContentNews(
    {
      type: CONTENT_TYPE.NEWS,
      page: 1,
      limit: search.variants.pagination.itemsPerPage,
      sort: route.query?.sort || 'order',
      direction: route.query?.direction || 'desc',
      locale: routeLang.locale.value
    },
    { fulltext: fulltext.value }
  );

  if (route.path !== translatePath(ROUTES.CODE.SEARCH)) {
    await navigatorTo({
      path: translatePath(ROUTES.CODE.SEARCH),
      query: _.assign(
        { fulltext: fulltext.value },
        routeCategory.idCategory.value ? { idCategory: routeCategory.idCategory.value } : {},
        routeCategory.idPostCategory.value ? { idPostCategory: routeCategory.idPostCategory.value } : {}
      )
    });
  } else {
    await useSearchParam('fulltext', fulltext.value);
  }
}, 500, { leading: false, trailing: true });

const clearSearch = async () => {
  fulltext.value = '';
  await useSearchParam('fulltext', fulltext.value);
}

const onFilter = async () => {
  if (route.path !== translatePath(ROUTES.CODE.SEARCH)) {
    await navigatorTo({
      path: translatePath(ROUTES.CODE.SEARCH),
      query: _.assign(
        { fulltext: fulltext.value },
        routeCategory.idCategory.value ? { idCategory: routeCategory.idCategory.value } : {},
        routeCategory.idPostCategory.value ? { idPostCategory: routeCategory.idPostCategory.value } : {}
      )
    });
    if (windowSize?.width?.value < 1024) {
      store.setAction('OPEN_FILTER_VIEW');
    } else {
      setTimeout(() => {
        $eventBus.emit('focus:filters');
      }, 500);
    }
  } else if (windowSize?.width?.value < 1024) {
    $eventBus.emit('sidebar:open', 'FILTER_VIEW');
  } else if (import.meta.client) {
    scrollToView();
  }
};

const onSpeech = () => {
  // TODO
  console.log('TODO.search.onSpeech');
};

</script>
