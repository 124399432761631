import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";

export default async function (name, value) {
  const route = useRoute();

  const routeName = computed(() => route?.name || '');

  const params = { ...route.query };
  if (_.isEmpty(value)) {
    delete params[name];
  } else if (_.get(params, name) != value) {
    params[name] = value;
  }
  await navigatorTo({
    path: routeName.value.startsWith('classified-ads___') ||
      routeName.value.startsWith('category-slug___') ||
      routeName.value.startsWith('news___') ||
      routeName.value.startsWith('news-about-slug___') ||
      routeName.value.startsWith('inspiration___') ||
      routeName.value.startsWith('inspiration-type-slug___') ||
      routeName.value.startsWith('favorites___') ||
      routeName.value.startsWith('search___') ||
      routeName.value.startsWith('offers___') ||
      routeName.value.startsWith('offers-slug___') ||
      routeName.value.startsWith('requests___') ||
      routeName.value.startsWith('requests-slug___') ||
      routeName.value.startsWith('auctions___') ||
      routeName.value.startsWith('auctions-slug___')
        ? route.path
        : translatePath(ROUTES.CODE.ADS),
    query: params,
  });
}
