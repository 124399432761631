import { API_ENDPOINTS } from "@utils/api/endpoints";
import { useSearchStore } from '@stores/search';

export default async function (params, body) {
  const search = useSearchStore();

  if (search.inspirationLoaded) {
    return { status: ref('success') };
  }

  search.setInspirationLoaded(false);

  const { data, error, status } = await useApiFetch(API_ENDPOINTS.CONTENT_POST, {
    method: 'POST',
    params,
    body
  });

  if (status.value === 'error') {
    sentryCaptureEvent({
      message: 'load-content-inspiration',
      level: 'error',
      extra: { error: error.value }
    });
  } else {
    search.setInspiration(data.value);
  }

  search.setInspirationLoaded(true);
  return { data, error, status };
}
