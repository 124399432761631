import { ROUTES } from "@utils/routes";
import { siteSettings } from "@settings/site.settings";

export default function () {
    const route = useRoute();
    const routeLang = useRouteLang();

    const routePath = computed(() => route?.path || '');

    const getCategoryId = (value) => {
        const lang = routeLang.code.value;
        const section = ROUTES[lang] || ROUTES[siteSettings.language.code];
        const prefix = lang === siteSettings.language.code ? '' : `/${lang}`;

        return (routePath.value.startsWith(`${prefix}${section['CATEGORY']}`)
            || routePath.value.startsWith(`${prefix}${section['OFFERS']}`)
            || routePath.value.startsWith(`${prefix}${section['REQUESTS']}`)
            || routePath.value.startsWith(`${prefix}${section['AUCTIONS']}`)
        ) && value ? parseInt(value) : null
    }

    const getPostCategoryId = (value) => {
        const lang = routeLang.code.value;
        const section = ROUTES[lang] || ROUTES[siteSettings.language.code];
        const prefix = lang === siteSettings.language.code ? '' : `/${lang}`;

        return (routePath.value.startsWith(`${prefix}${section['INSPIRATION_TYPE']}`)
            || routePath.value.startsWith(`${prefix}${section['NEWS_ABOUT']}`)
        ) && value ? parseInt(value) : null
    }

    const normalizeId = (value) => value ? value : null;

    const idCategory = ref(normalizeId(getCategoryId(route.params.slug) || +route?.query?.idCategory || null));
    const idPostCategory = ref(normalizeId(getPostCategoryId(route.params.slug) || +route?.query?.idPostCategory || null));

    watch(() => route.query, (value) => {
        idCategory.value = normalizeId(getCategoryId(route.params.slug) || +value?.idCategory || null);
        idPostCategory.value = normalizeId(getPostCategoryId(route.params.slug) || +value?.idPostCategory || null);
    }, { deep: true });

    watch(() => route.params, (value) => {
        idCategory.value = normalizeId(getCategoryId(value.slug) || +route?.query?.idCategory || null);
        idPostCategory.value = normalizeId(getPostCategoryId(value.slug) || +route?.query?.idPostCategory || null);
    }, { deep: true });

    const isMain = computed(() => !idCategory.value || idCategory.value < 100);

    return { idCategory, idPostCategory, isMain };
}
