<template>
    <div
      :class="[ 'flex flex-row items-center space-x-3 cursor-pointer hover:underline focus:underline', accent ? 'text-accent-dark hover:text-accent-dark-hover' : 'text-primary-dark hover:text-primary-dark-hover' ]"
      @click="handleClick"
    >
        <ShareIcon :class="[ 'w-4 h-4', accent ? 'text-accent-dark hover:text-accent-dark-hover' : 'text-primary-dark hover:text-primary-dark-hover' ]" />
        <span class="text-base font-bold text-right">{{ $t('text-share-news') }}</span>
    </div>
</template>

<script setup>
import { useUIStore } from '@stores/ui';
import { useSelectedStore } from '@stores/selected';
import ShareIcon from "@components/icons/outline/share-icon";

const props = defineProps({
  variant: {
    type: Object,
    default: () => {}
  },
  accent: {
    type: Boolean,
    default: false
  }
});

const { $eventBus } = useNuxtApp();
const ui = useUIStore();
const store = useSelectedStore();

const handleClick = () => {
  if (ui.modalView && ui.displayModal) {
    store.setAction('RETURN_TO_NEWS_MODAL');
    $eventBus.emit('modal:close');
  }
  store.setContent(props.variant);
  $eventBus.emit('modal:open', 'SHARE_NEWS_LINK_MODAL');
}

</script>
