import * as Sentry from "@sentry/vue";
import { API_ENDPOINTS } from "@utils/api/endpoints";

export default async function (idProduct, idVariant) {
  const config = useRuntimeConfig();
  const routeLang = useRouteLang();

  try {
    return await $fetch(`${config.public.appBaseUrl}${API_ENDPOINTS.ESHOP_PRODUCT_VARIANT_ID_TRANSLATION}`.replace('{idProduct}', idProduct).replace('{idVariant}', idVariant), {
      method: 'GET',
      path: { idProduct, idVariant },
      params: {
        locale: routeLang.locale.value
      }
    });
  } catch (error) {
    Sentry.captureException(error);
  }

  return null;
}
