<template>
  <div class="bg-light w-screen md:max-w-md h-screen md:h-auto flex flex-col justify-center overflow-hidden">
    <Scrollbar class="py-6 px-5 sm:p-8 w-full max-h-screen">
      <div class="flex justify-center">
        <Logo />
      </div>
      <p v-if="!linkSent" class="text-center text-sm md:text-base text-body mt-4 sm:mt-5 mb-4 sm:mb-5">
        {{ store.action === 'ADD_TO_CART' ? $t('text-register-auth-message') : '' }}
      </p>
      <form v-if="!linkSent" noValidate @submit.prevent="">
        <Input
          v-model="name"
          :label="$t('text-name')"
          name="name"
          type="text"
          variant="outline"
          class-name="mb-5"
          :error="errors?.name?.message ? $t(errors.name.message) : ''"
          :required="true"
        />
        <Input
          v-model="surname"
          :label="$t('text-surname')"
          name="surname"
          type="text"
          variant="outline"
          class-name="mb-5"
          :error="errors?.surname?.message ? $t(errors.surname.message) : ''"
          :required="true"
        />
        <Input
          v-model="email"
          :label="$t('text-email')"
          name="email"
          type="text"
          variant="outline"
          class-name="mb-5"
          :error="errors?.email?.message ? $t(errors.email.message) : ''"
          :required="true"
        />
        <PasswordInput
          v-model="password"
          :label="$t('text-password')"
          name="password"
          variant="outline"
          class-name="mb-5"
          :error="errors?.password?.message ? $t(errors.password.message) : ''"
          :required="true"
        />
        <Input
          v-if="!showAddress"
          v-model="address.city"
          :label="$t('text-city')"
          name="city"
          type="text"
          variant="outline"
          class-name="mb-5"
          :error="errors?.address?.city?.message ? $t(errors.address.city.message) : ''"
          :required="true"
        />
        <div v-if="!showAddress" class="flex justify-center">
          <Button
            size="small"
            :on-click="toggleShowAddress"
          >
            <div class="flex items-center relative -left-1">
              <PlusIcon class="w-6 h-6" />
              <div>{{ $t("text-add-supplier-info") }}</div>
            </div>
          </Button>
        </div>
        <SupplierAddress v-else v-model="address" :errors="errors.address" />
        <Checkbox
          v-model="isConsentConditions"
          name="isConsentConditions"
          :label="$t('text-agree-with')"
          variant="outline"
          class="w-full mt-4"
          :error="errors?.isConsentConditions?.message ? $t(errors.isConsentConditions.message) : ''"
          :required="true"
        >
          <button
            class="underline text-accent font-semibold transition-colors duration-200 focus:outline-none hover:text-accent-hover focus:text-accent-hover hover:no-underline focus:no-underline"
            @click="openTermsSidebar"
          >
            {{ $t('text-with-terms-and-conditions') }}
          </button>
        </Checkbox>
        <Checkbox
          v-model="isConsentPrivacy"
          name="isConsentPrivacy"
          :label="$t('text-agree-withs')"
          variant="outline"
          class="w-full mt-4"
          :error="errors?.isConsentPrivacy?.message ? $t(errors.isConsentPrivacy.message) : ''"
          :required="true"
        >
          <button
            class="underline text-accent font-semibold transition-colors duration-200 focus:outline-none hover:text-accent-hover focus:text-accent-hover hover:no-underline focus:no-underline"
            @click="openPrivacySidebar"
          >
            {{ $t('text-privacy-policy') }}
          </button>
        </Checkbox>
        <div class="mt-8">
          <Button
            class-name="w-full h-12"
            :loading="loading"
            :disabled="loading"
            :on-click="handleSubmit"
          >
            {{ $t('text-register') }}
          </Button>
        </div>
      </form>
      <Alert
        v-if="errorMsg"
        :variant="errorType"
        :message="errorMsg"
        class="mt-6"
        :closeable="!linkSent"
        :on-close="() => errorMsg = ''"
      >
        <button
          v-if="errorMsg === 'error.register.user-exists'"
          class="underline text-sm font-semibold transition-colors duration-200 focus:outline-none hover:no-underline focus:no-underline"
          @click="() => openModal('FORGOT_VIEW')"
        >
          {{ $t('error.register.user-exists.link.label') }}.
        </button>
      </Alert>
      <Button
        v-if="linkSent"
        class-name="w-full h-12 mt-5"
        :on-click="handleClose"
      >
        <div class="flex items-center space-x-1">
          <div>{{ $t('text-continue') }}</div>
          <ArrowNarrowLeft class="w-6 h-6 transform rotate-180" />
        </div>
      </Button>

      <div v-if="!linkSent" class="flex flex-col items-center justify-center relative text-sm text-bolder mt-8 sm:mt-11 mb-6 sm:mb-8">
        <hr class="w-full" >
        <span class="absolute start-2/4 -top-2.5 px-2 -ms-4 bg-light">
          {{ $t('text-or') }}
        </span>
      </div>
      <div v-if="!linkSent" class="text-sm sm:text-base text-body text-center">
        {{ $t('text-already-account') }}
        <button
          class="underline text-accent font-semibold transition-colors duration-200 focus:outline-none hover:text-accent-hover focus:text-accent-hover hover:no-underline focus:no-underline"
          @click="() => openModal('LOGIN_VIEW')"
        >
          {{ $t("text-login") }}
        </button>
      </div>
    </Scrollbar>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { useAuthStore } from '@stores/auth';
import { useSelectedStore } from '@stores/selected';
import { useCustomerStore } from '@stores/customer';
import { emptyAddress } from '@data/empty-address';
import Scrollbar from "@components/ui/scrollbar";
import Logo from "@components/ui/logo";
import Alert from "@components/ui/alert";
import Input from "@components/ui/input";
import PasswordInput from "@components/ui/password-input";
import Button from "@components/ui/button";
import Checkbox from "@components/ui/checkbox";
import ArrowNarrowLeft from "@components/icons/arrow-narrow-left";
import PlusIcon from "@components/icons/plus-icon";
import SupplierAddress from "@components/auth/supplier-address";

const loading = ref(false);
const linkSent = ref(false);
const name = ref('');
const surname = ref('');
const email = ref('');
const password = ref('');
const isConsentConditions = ref(false);
const isConsentPrivacy = ref(false);
const errors = ref({ address: {} });
const errorMsg = ref('');
const errorType = ref('error');
const showAddress = ref(false);
const address = ref(_.assign({}, _.cloneDeep(emptyAddress), { images: [] }));

const { $eventBus } = useNuxtApp();
const route = useRoute();
const auth = useAuthStore();
const customer = useCustomerStore();
const store = useSelectedStore();
const router = useRouter();

const openModal = (view) => {
    $eventBus.emit('modal:close');
    $eventBus.emit('modal:open', view);
}

const openTermsSidebar = () => {
    $eventBus.emit('sidebar:open', 'TERMS');
}

const openPrivacySidebar = () => {
    $eventBus.emit('sidebar:open', 'PRIVACY');
}

const routeName = computed(() => route?.name || '');

const closeModal = async () => {
    $eventBus.emit('modal:close');
    if (route?.query?.redirect_uri) {
      router.replace(route?.query?.redirect_uri);
    } else if (routeName.value.startsWith('register___')) {
      await navigatorTo(translatePath(ROUTES.CODE.HOME));
    }
}

const validateAddress = () => {
  if (address.value.company && !address.value.companyNo) {
    errors.value.address.companyNo = { message: 'text-companyNo-required' };
  }
  if (!address.value.street) {
    errors.value.address.street = { message: 'error-street-required' };
  }
  if (!address.value.streetNo) {
    errors.value.address.streetNo = { message: 'error-street-no-required' };
  }
  if (!address.value.city) {
    errors.value.address.city = { message: 'error-city-required' };
  }
  if (address.value.city === password.value) {
    errors.value.address.city = { message: 'error-valid-city-required' };
  }
  if (!address.value.zip) {
    errors.value.address.zip = { message: 'error-zip-required' };
  }
  if (!address.value.idCountry) {
    errors.value.address.country = { message: 'error-country-required' };
  }
  if (!address.value.phone) {
    errors.value.address.phone = { message: 'error-phone-required' };
  }
  if (address.value.phone && /^[+]?[0-9\s-]{9,}$/.test(address.value.phone) === false) {
    errors.value.address.phone = { message: 'error-phone-numeric' };
  }
}

const isValid = () => {
  errors.value = { address: {} };
  errorMsg.value = '';
  if (!name.value) {
    errors.value.name = { message: 'error-name-required' };
  }
  if (!surname.value) {
    errors.value.surname = { message: 'error-surname-required' };
  }
  if (!email.value) {
    errors.value.email = { message: 'error-email-required' };
  }
  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.value) === false) {
    errors.value.email = { message: 'error-email-format' };
  }
  if (!password.value) {
    errors.value.password = { message: 'error-password-required' };
  }
  if (!showAddress.value && !address.value.city) {
    errors.value.address.city = { message: 'error-city-required' };
  }
  if (!showAddress.value && address.value.city === password.value) {
    errors.value.address.city = { message: 'error-valid-city-required' };
  }
  if (!isConsentConditions.value) {
    errors.value.isConsentConditions = { message: 'error.register.consent.conditions.false' };
  }
  if (!isConsentPrivacy.value) {
    errors.value.isConsentPrivacy = { message: 'error.register.consent.privacy.false' };
  }
  if (showAddress.value) {
    validateAddress();
  }
  if (!_.every(_.values(errors.value), _.isEmpty)) {
    errorMsg.value = 'error-some-fields-required';
    $eventBus.emit('focus:error', _.chain(errors.value)
      .omit('address')
      .keys()
      .concat(_.chain(errors.value.address).keys().value())
      .first()
      .value()
    );
    return false;
  }

  return true;
}

const register = async (body, callbacks) => {
    loading.value = true;
    const { data, error, status } = await useApiFetch(API_ENDPOINTS.CUSTOMER, {
      method: 'POST',
      body
    });
    if (status.value === 'error') {
      callbacks.onError(error.value);
    } else {
      await callbacks.onSuccess(data.value);
    }
    loading.value = false;

    return status.value !== 'error';
};

const updateSupplier = async (body, callbacks) => {
    loading.value = true;
    const { data, error, status } = await useApiFetch(API_ENDPOINTS.SUPPLIER, {
      method: 'PUT',
      body
    });
    if (status.value === 'error') {
      callbacks.onError(error.value);
    } else {
      callbacks.onSuccess(data.value);
    }
    loading.value = false;
};

const handleSubmit = async () => {
  if (!isValid()) {
    return;
  }
  const updated = await register({
      firstName: name.value,
      lastName: surname.value,
      email: email.value,
      password: password.value,
      city: address.value.city
    },
    {
      onSuccess: async(data) => {
        if (data.sid && data.isLoggedIn) {
          auth.setData(data);
          await customer.load();
          await checkSupplier();
          address.value.firstName = name.value;
          address.value.lastName = surname.value;
          address.value.email = email.value;
          // await cartLoad();
          store.setLoadResetAll();
          errorMsg.value = 'text-register-succes-message';
          errorType.value = 'success';
          linkSent.value = true;
        } else {
          errorMsg.value = 'error.register.unknown';
        }
      },
      onError: (error) => {
        if (error.statusCode === 400) {
          errorMsg.value = 'error.register.user-exists';
        } else {
          errorMsg.value = 'error.register.unknown';
        }
        sentryCaptureEvent({
          message: 'register.handleSubmit.register',
          level: 'error',
          extra: { error }
        });
      },
    }
  );
  if (updated && showAddress.value) {
    const keys = _.keys(_.omit(emptyAddress, ['id', 'country', 'calling', 'latitude', 'longitude', 'images']));
    await updateSupplier(
      Object.assign({
        billingAddress: _.pick(address.value ? address.value : address.value, keys),
        deliveryAddress: _.pick(address.value, keys),
        name: customer.fullName || '',
      }, address.value.images.length ? { logo: _.get(address.value.images, '0.encoded') } : {}),
      {
        onSuccess: (data) => {
          data.distanceKm = 0;
          address.value.images = [];
          customer.setSupplier(data);
        },
        onError: (error) => {
          sentryCaptureEvent({
            message: 'register.handleSubmit.updateSupplier',
            level: 'error',
            extra: { error }
          });
        }
      }
    );
  } else {
    customer.setSupplier({});
    auth.setNotificationInfo({});
  }
}

const handleClose = async () => {
  if (store.action) {
    $eventBus.emit('modal:close');
  }
  const result = await evalStoreAction();
  if (!result) {
    await closeModal();
  }
}

const toggleShowAddress = () => {
  showAddress.value = true;
}

</script>
