<template>
    <div v-tippy="$t('text-comments')" class="flex flex-row items-center space-x-0.5 cursor-pointer" @click="onClick">
        <ChatBubbleSolid v-if="messagesCount" :class="[ 'w-4 h-4', accent ? 'text-card-icon hover:text-card-icon-hover' : 'text-primary-dark hover:text-primary-dark-hover' ]" />
        <ChatBubble v-else :class="[ 'w-4 h-4', accent ? 'text-card-icon hover:text-card-icon-hover' : 'text-primary-dark hover:text-primary-dark-hover' ]" />
        <div v-if="messagesCount" :class="[ 'text-xs font-normal', accent ? 'text-light hover:text-light-hover' : 'text-primary-dark hover:text-primary-dark-hover' ]">
            {{ messagesCount }}
        </div>
        <div v-if="label" :class="[ 'text-xs font-normal', accent ? 'text-light hover:text-light-hover' : 'text-primary-dark hover:text-primary-dark-hover' ]">
            {{ countString(messagesCount, $t(label)) }}
        </div>
    </div>
</template>

<script setup>
import ChatBubble from "@components/icons/outline/chat-bubble";
import ChatBubbleSolid from "@components/icons/solid/chat-bubble-solid";

const props = defineProps({
  variant: {
    type: Object,
    default: () => {}
  },
  label: {
    type: String,
    default: null
  },
  onClick: {
    type: Function,
    default: () => {}
  },
  accent: {
    type: Boolean,
    default: false
  }
});

const messagesCount = computed(() => +(props.variant?.messagesCount || 0));

</script>
