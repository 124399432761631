import { API_ENDPOINTS } from "@utils/api/endpoints";
import { useAuthStore } from '@stores/auth';
import { useCartStore } from '@stores/cart';
import { useSelectedStore } from '@stores/selected'
import { useCustomerStore } from '@stores/customer';

export default async function () {
  const auth = useAuthStore();
  const cart = useCartStore();
  const store = useSelectedStore();
  const customer = useCustomerStore();
  const routeLang = useRouteLang();

  if (!auth.isLoggedIn) {
    customer.setLoading(false);
    cart.setLoading(false);

    return;
  }

  customer.setLoading(true);
  cart.setLoading(true);

  const { data, error, status } = await useApiFetch(API_ENDPOINTS.MASTER_PRIVATE, {
    params: {
      locale: routeLang.locale.value
    }
  });

  if (status.value === 'error') {
    sentryCaptureEvent({
      message: 'load-private-master',
      level: 'error',
      extra: { error: error.value }
    });

    auth.reset();
    customer.reset();
    cart.reset();
    store.setLoadResetAll();

} else {
    // visitor
    customer.setLanguage(data.value?.visitor?.language || {});
    customer.setCurrency(data.value?.visitor?.currency || {});

    // customer
    customer.setData(data.value?.customer || {});

    // supplier
    customer.setSupplier(data.value?.supplier || {});

    // cart
    cart.list.items = data.value?.carts?.items || [];
    cart.pagination = data.value?.carts?.pagination || cart.pagination;
  }

  customer.setLoading(false);
  cart.setLoading(false);
}
