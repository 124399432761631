import _ from 'lodash-es';
import { defineStore } from 'pinia'
import { CONTENT_KEY } from "@utils/constants";

export const useContentStore = defineStore(CONTENT_KEY, () => {
  const empty = { items: [], pagination: { page: 1, pageCount: 1, itemsPerPage: 24, itemCount: 0 } };
  const ttl = 5;

  const loaded = useState('content.loaded', () => false);
  const resetLoaded = () => {
    loaded.value = _.cloneDeep(empty);
  }
  const setLoaded = (value) => {
    loaded.value = value;
  }

  const variants = useState('content.variants', () => _.cloneDeep(empty));
  const resetVariants = () => {
    variants.value = _.cloneDeep(empty);
  }
  const setVariants = (value) => {
    variants.value = value;
    setTimeout(resetVariants, ttl * 1000);
  }

  const inspiration = useState('content.inspiration', () => _.cloneDeep(empty));
  const resetInspiration = () => {
    inspiration.value = _.cloneDeep(empty);
  }
  const setInspiration = (value) => {
    inspiration.value = value;
    setTimeout(resetInspiration, ttl * 1000);
  }

  const news = useState('content.news', () => _.cloneDeep(empty));
  const resetNews = () => {
    news.value = _.cloneDeep(empty);
  }
  const setNews = (value) => {
    news.value = value;
    setTimeout(resetNews, ttl * 1000);
  }

  const reference = useState('content.reference', () => _.cloneDeep(empty));
  const resetReference = () => {
    reference.value = _.cloneDeep(empty);
  }
  const setReference = (value) => {
    reference.value = value;
    setTimeout(resetReference, ttl * 1000);
  }

  return {
    loaded, setLoaded, resetLoaded,
    variants, setVariants, resetVariants,
    inspiration, setInspiration, resetInspiration,
    news, setNews, resetNews,
    reference, setReference, resetReference,
  }
})
