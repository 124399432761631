export default function () {
    const wasUserActive = useState('activityChecker.wasUserActive', () => false);
    const isUserActive = useState('activityChecker.isUserActive', () => false);

    const inactivityTimeout = ref(null);
    const inactivityTime = 1000;

    const resetActivityTimer = () => {
        wasUserActive.value = true;
        isUserActive.value = true;
        clearTimeout(inactivityTimeout.value);
        inactivityTimeout.value = setTimeout(() => {
            isUserActive.value = false;
        }, inactivityTime);
    };

    const setupActivityListeners = () => {
        const events = ["mousemove", "keydown", "touchstart"];
        events.forEach((event) =>
            window.addEventListener(event, resetActivityTimer)
        );
    };

    const removeActivityListeners = () => {
        const events = ["mousemove", "keydown", "touchstart"];
        events.forEach((event) =>
            window.removeEventListener(event, resetActivityTimer)
        );
    };

    onMounted(() => {
        setupActivityListeners();
    });

    onBeforeUnmount(() => {
        removeActivityListeners();
        clearTimeout(inactivityTimeout.value);
    });

  return { wasUserActive, isUserActive };
}
