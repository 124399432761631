<template>
  <div>
    <ContentDetailGallery :variant="variant" class="float-none md:float-left mr-0 md:mr-5" />
    <div class="flex flex-row items-start justify-between space-x-2 mb-4 md:mb-8 pt-5 pl-5 md:pl-0 pr-5 md:pr-10">
      <h1 class="font-bold text-2xl tracking-tight text-primary-dark cursor-pointer transition-colors hover:text-primary-dark-hover no-underline hover:underline" @click="navigate">
          {{ variant.name }}
      </h1>
      <div class="flex items-center justify-end space-x-2">
        <NewsNotificationButton :variant="variant" class="flex-shrink-0" />
        <NewsFavorite :variant="variant" class="flex-shrink-0" />
      </div>
    </div>

    <div class="flex items-center justify-between space-x-2 mb-9 ml-5 md:ml-0 pr-5 md:pr-10">
      <ProfileHeader :data="variant" />
      <NewsShare :variant="variant" />
    </div>

    <div class="text-sml text-body font-normal mb-9 pl-5 md:pl-10 pr-5 md:pr-10">
      {{ localized(variant, 'perex') }}
    </div>

    <div class="prose max-w-none mb-9 pl-5 md:pl-10 pr-5 md:pr-10" v-html="localized(variant, 'content')" />

    <div class="w-full flex flex-row items-center justify-between space-x-2 mb-9 pl-5 md:pl-10 pr-5 md:pr-10">
      <div class="flex flex-row items-center justify-start space-x-2.5">
        <ContentViews :variant="variant" />
        <ContentLikes :variant="variant" />
      </div>
      <NewsNotificationLink class="text-primary-light sm:max-w-fit" />
    </div>

  </div>
</template>

<script setup>
import { ROUTES } from "@utils/routes";
import ProfileHeader from "@components/profile/profile-header";
import NewsFavorite from "@components/news/news-details/news-favorite";
import NewsNotificationButton from "@components/news/news-details/news-notification-button";
import NewsNotificationLink from "@components/news/news-details/news-notification-link";
import ContentViews from "@components/content/content-details/content-views";
import ContentLikes from "@components/content/content-details/content-likes";
import ContentDetailGallery from "@components/content/content-details/content-detail-gallery";
import NewsShare from "@components/news/news-details/news-share";

const props = defineProps({
  variant: {
    type: Object,
    required: true
  },
  size: {
    type: String,
    default: 'page' //page, modal
  }
})

const { $eventBus } = useNuxtApp();

const routeCode = computed(() => {
  return {
    inspiration: ROUTES.CODE.INSPIRATION,
    news: ROUTES.CODE.NEWS
  }[props.variant?.type] || ROUTES.CODE.PRODUCT;
});

const closeModal = () => {
    $eventBus.emit('modal:close');
}

const navigate = async () => {
    closeModal();
    await navigatorTo(translatePath(routeCode.value, slugify(props.variant?.id, props.variant?.name)));
}

</script>
